<template>
  <!-- Page Content -->
  <div class="my-2">
    <div>
      <h2 class="my-4 text-4xl font-semibold">Social Chat Settings </h2>
      <div
        class="pb-2 flex items-center justify-between text-gray-600 border-b"
      >
        <p>Edit the Social Chat Settings interactive Social Chat list settings</p>
      </div>
    </div>

    <div class="w-full mt-5">
      <div class="flex flex-col w-full md:w-1/2 lg:w-1/3 text-left p-2">
        <label class="w-full">Social Chat Platforms:</label>
        <select
          class="lb-admin-form-input rounded"
          v-model="socialChatSetting.platforms"
        >
          <option value="whatsapp">WhatsApp</option>
          <option value="telegram">Telegram</option>
          <option value="messenger">Messenger</option>
          <option value="wechat">WeChat</option>
          <option value="twitter">Twitter</option>
          <option value="viber">Viber</option>
          <option value="line">Line</option>
          <option value="email">Email</option>
        </select>
      </div>
    </div>

    <div class="w-full mt-5">
      <div class="flex w-full">
        <div class="flex flex-col w-full md:w-1/2 lg:w-1/4 text-left p-2">
          <label class="w-full">Chat Style:</label>
          <select
            class="lb-admin-form-input rounded"
            v-model="socialChatSetting.chatStyle"
          >
            <option value="whatsapp-chat">WhatsApp Style</option>
            <option value="telegram-chat">Telegram Style</option>
            <option value="messenger-chat">Messenger Style</option>
            <option value="wechat-chat">WeChat Style</option>
            <option value="twitter-chat">Twitter Style</option>
            <option value="viber-chat">Viber Style</option>
            <option value="line-chat">Line Style</option>
            <option value="email-chat">Email Style</option>
          </select>
        </div>

        <div class="flex flex-col w-full md:w-1/2 lg:w-1/4 text-left p-2">
          <label class="w-full">Header Message Platform:</label>
          <select
            class="lb-admin-form-input rounded"
            v-model="socialChatSetting.headerMessagePlatform"
          >
            <option value="WhatsApp">WhatsApp</option>
            <option value="Telegram">Telegram</option>
            <option value="Messenger">Messenger</option>
            <option value="WeChat">WeChat</option>
            <option value="Twitter">Twitter</option>
            <option value="Viber">Viber</option>
            <option value="Line">Line</option>
            <option value="Email">Email</option>
          </select>
        </div>

        <div class="flex flex-col w-full md:w-1/2 lg:w-1/4 text-left p-2">
          <label class="w-full">Icon Src:</label>
          <select
            class="lb-admin-form-input rounded"
            v-model="socialChatSetting.iconSrc"
          >
            <option
              value="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/whatsapp.svg"
            >
              WhatsApp Icon Src
            </option>
            <option
              value="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/telegram.svg"
            >
              Telegram Icon Src
            </option>
            <option
              value="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/messenger.svg"
            >
              Messenger Icon Src
            </option>
            <option
              value="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/wechat.svg"
            >
              WeChat Icon Src
            </option>
            <option
              value="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/twitter.svg"
            >
              Twitter Icon Src
            </option>
            <option
              value="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/viber.svg"
            >
              Viber Icon Src
            </option>
            <option
              value="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/line.svg"
            >
              Line Icon Src
            </option>
            <option
              value="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/email.svg"
            >
              Email Icon Src
            </option>
          </select>
        </div>

        <div class="flex flex-col w-full md:w-1/2 lg:w-1/4 text-left p-2">
          <label class="w-full">Icon Alt:</label>
          <select
            class="lb-admin-form-input rounded"
            v-model="socialChatSetting.iconAlt"
          >
            <option value="icon whatsapp">WhatsApp Icon Alt</option>
            <option value="icon telegram">Telegram Icon Alt</option>
            <option value="icon messenger">Messenger Icon Alt</option>
            <option value="icon wechat">WeChat Icon Alt</option>
            <option value="icon twitter">Twitter Icon Alt</option>
            <option value="icon viber">Viber Icon Alt</option>
            <option value="icon line">Line Icon Alt</option>
            <option value="icon email">Email Icon Alt</option>
          </select>
        </div>
      </div>

      <div class="flex w-full">
        <div class="flex flex-col w-full md:w-1/2 lg:w-1/4 text-left p-2">
          <label class="w-full">Footer Details:</label>
          <select
            class="lb-admin-form-input rounded"
            v-model="socialChatSetting.footerDetails"
          >
            <option value="Default Footer">Default Footer</option>
            <option value="Custom Footer">Custom Footer</option>
            <option value="No Footer">No Footer</option>
          </select>
        </div>
      </div>

      <div class="flex w-full" v-if="socialChatSetting.footerDetails === 'Default Footer'">
        <div class="flex flex-col w-full md:w-1/2 lg:w-1/4 text-left p-2">
          <label class="w-full">Company Contact</label>
          <VuePhoneNumberInput
            v-model="socialChatSetting.companyContact"
            @update="updatePhoneNumber"
            class="phone-container"
            size="md"
            color="#aa9461"
            default-country-code="ZA"
          />
        </div>

        <div class="flex flex-col w-full md:w-1/2 lg:w-1/4 text-left p-2">
          <label class="w-full">Tool Pick:</label>
          <input
            v-model="socialChatSetting.toolPick"
            class="lb-admin-form-input rounded"
            type="text"
            placeholder="Contact Us"
          />
        </div>

        <div class="flex flex-col w-full md:w-1/2 lg:w-1/4 text-left p-2">
          <label class="w-full">Hours From:</label>
          <input
            v-model="socialChatSetting.hoursFrom"
            class="lb-admin-form-input rounded"
            type="text"
            placeholder="8:00"
          />
        </div>

        <div class="flex flex-col w-full md:w-1/2 lg:w-1/4 text-left p-2">
          <label class="w-full">Hours To:</label>
          <input
            v-model="socialChatSetting.hoursTo"
            class="lb-admin-form-input rounded"
            type="text"
            placeholder="18:00"
          />
        </div>
      </div>

      <div class="flex w-full" v-if="socialChatSetting.footerDetails === 'Custom Footer'">
        <div class="flex flex-col w-full md:w-1/2 lg:w-1/2 text-left p-2">
          <label class="w-full">Custom Footer:</label>
          <input
            v-model="socialChatSetting.customFooter"
            class="lb-admin-form-input rounded"
            type="text"
          />
        </div>
      </div>
    </div>

    <div class="mt-5 pb-5 flex items-center justify-between text-gray-600 border-t">
      <div class="mt-5">
        <span>
          <span class="text-primary"> {{ totalAttendants }} </span>
          attendants
        </span>
      </div>
      <button
        class="w-40 mt-5 flex flex-row items-center justify-center p-2 text-white font-semibold bg-primary rounded cursor-pointer"
        type="button"
        v-if="hasAccess('attendants', 'add')"
        @click="gotToAddAttendant()"
      >
        Add Attendant
      </button>
    </div>

    <input
      type="search"
      v-model="query"
      placeholder="Search..."
      class="w-full p-2 mb-2"
    />

    <!-- Attendant Table Content -->
    <table
      class="min-w-max w-full mt-5 table-auto paginated table-fixed" style="padding: 10px"
      :sticky-header="true"
      :data="filteredAttendants"
      :bordered="true"
      :striped="true"
      :narrowed="true"
      :hoverable="true"
      :mobile-cards="true"
      :paginated="true"
      :per-page="10"
    >
      <!-- Attendant Table Content Header -->
      <thead>
      <tr
        v-for="attendant in sortedAttendants"
        :key="attendant"
        class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal"
      >
        <th
          class="px-2 py-1 text-center"
          v-if="hasAccess('attendants', 'edit')"
        >
          Edit
        </th>
        <th
          @click="sort('socialApp')"
          class="heading-text"
          :class="{ sorting: currentSort === 'socialApp' }"
        >
          App
          <MenuDown
            v-if="isSorted('socialApp', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('socialApp', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('titleLabel')"
          class="heading-text"
          :class="{ sorting: currentSort === 'titleLabel' }"
        >
          Label
          <MenuDown
            v-if="isSorted('titleLabel', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('titleLabel', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('fullName')"
          class="heading-text"
          :class="{ sorting: currentSort === 'fullName' }"
        >
          Name
          <MenuDown
            v-if="isSorted('fullName', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('fullName', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          v-if="attendant.socialApp === 'whatsapp' || attendant.socialApp === 'viber'"
          @click="sort('contactNumber')"
          class="heading-text"
          :class="{ sorting: currentSort === 'contactNumber' }"
        >
          Phone number
          <MenuDown
            v-if="isSorted('contactNumber', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('contactNumber', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          v-if="attendant.socialApp === 'telegram' || attendant.socialApp === 'line'"
          @click="sort('Username')"
          class="heading-text"
          :class="{ sorting: currentSort === 'Username' }"
        >
          Username
          <MenuDown
            v-if="isSorted('Username', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('Username', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          v-if="attendant.socialApp === 'messenger' || attendant.socialApp === 'wechat' || attendant.socialApp === 'twitter'"
          @click="sort('userAppId')"
          class="heading-text"
          :class="{ sorting: currentSort === 'userAppId' }"
        >
          User App Id
          <MenuDown
            v-if="isSorted('userAppId', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('userAppId', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          v-if="attendant.socialApp === 'email'"
          @click="sort('href')"
          class="heading-text"
          :class="{ sorting: currentSort === 'href' }"
        >
          href
          <MenuDown
            v-if="isSorted('href', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('href', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('avatar')"
          class="heading-text"
          :class="{ sorting: currentSort === 'avatar' }"
        >
          Avatar
          <MenuDown
            v-if="isSorted('avatar', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('avatar', 'asc')"
            class="inline-block absolute"
          />
        </th>
      </tr>
      </thead>
      <!-- Attendant Table Content Body -->
      <tbody class="text-gray-600 text-sm font-light">
      <tr
        v-for="attendant in sortedAttendants"
        :key="attendant"
        class="border-b border-gray-200 hover:bg-gray-100"
      >
        <td
          class="py-3 px-3 text-center"
          v-if="hasAccess('attendants', 'edit')"
        >
          <div class="flex item-center justify-center">
            <router-link
              :to="{
                  name: 'admin-update-attendant',
                  params: { id: attendant.attendantsSocialChatID },
                }"
              class="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
            >
              <EditIcon />
            </router-link>
          </div>
        </td>
        <td class="py-3 px-3 text-center">
          {{ attendant.socialApp }}
        </td>
        <td class="py-3 px-3 text-center">
          {{ attendant.titleLabel }}
        </td>
        <td class="py-3 px-3 text-center">
          {{ attendant.fullName }}
        </td>
        <td
          v-if="attendant.socialApp === 'whatsapp' || attendant.socialApp === 'viber'"
          class="py-3 px-3 text-center"
        >
          {{ attendant.contactNumber }}
        </td>
        <td
          v-if="attendant.socialApp === 'telegram' || attendant.socialApp === 'line'"
          class="py-3 px-3 text-center"
        >
          {{ attendant.username }}
        </td>
        <td
          v-if="attendant.socialApp === 'messenger' || attendant.socialApp === 'wechat' || attendant.socialApp === 'twitter'"
          class="py-3 px-3 text-center"
        >
          {{ attendant.userAppId }}
        </td>
        <td
          v-if="attendant.socialApp === 'email'"
          class="py-3 px-3 text-center"
        >
          {{ attendant.href }}
        </td>
        <td class="py-3 px-3 text-center">
          {{ attendant.avatar }}
        </td>
      </tr>
      </tbody>
    </table>
    <!-- Attendant Table Content Pagination Btn -->
    <p class="pagination-container">
      <button
        v-if="currentPage != 1"
        @click="prevPage"
        class="mb-3 px-2 py-1 mr-1 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none bg-primary border border-solid border-primary focus:outline-none"
      >
        &lt; Prev
      </button>

      <button
        v-if="(this.currentPage*this.pageSize) < this.filteredAttendants.length"
        @click="nextPage"
        class="mb-3 px-2 py-1 mr-1 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none bg-primary border border-solid border-primary focus:outline-none"
      >
        Next &gt;
      </button>
    </p>

    <!-- Page Content -->
    <div class="w-full mt-10 pt-5 border-t">
      <button
        @click.prevent="updateSocialChatSettings"
        class="bg-primary px-4 py-2 text-white font-semibold rounded"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import {socialCollection, settingsCollection} from "../../firebase.js";
import isNil from "lodash/isNil";
import VuePhoneNumberInput from 'vue-phone-number-input';
import permissionsMixin from "../../mixins/permissions";

import EditIcon from "vue-material-design-icons/PencilOutline";
import MenuDown from "vue-material-design-icons/MenuDown";
import MenuUp from "vue-material-design-icons/MenuUp";

export default {
  name: "AdminAttendantsList",
  components: {
    VuePhoneNumberInput,
    EditIcon,
    MenuDown,
    MenuUp,
  },
  mixins: [permissionsMixin],
  data: () => ({
    currentSort:'socialApp',
    currentSortDir:'desc',
    pageSize:10,
    currentPage:1,
    query: "",
    allAttendants: [],
    attendants: [],
    socialChatSetting: {
      platforms: null,
      chatStyle: null,
      headerMessagePlatform: null,
      iconSrc: null,
      iconAlt: null,
      footerDetails: null,
      companyContact: null,
      e164ContactNumber: '',
      formatNationalContactNumber: '',
      toolPick: null,
      hoursFrom: null,
      hoursTo: null,
      customFooter: null,
    },
  }),
  methods: {
    isSorted(field, direction) {
      if (this.currentSort === field && this.currentSortDir === direction) {
        return true;
      }
      return false;
    },
    sort:function(s) {
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='desc'?'asc':'desc';
      }
      this.currentSort = s;
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredAgents.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    gotToAddAttendant() {
      this.$router.replace({ name: "admin-add-attendant" });
    },
    getAttendants() {
      socialCollection.orderBy("socialApp").onSnapshot((snap) => {
        let attendants = [];
        snap.forEach((doc) => {
          attendants.push({
            socialApp: doc.data().socialApp,
            titleLabel: doc.data().titleLabel,
            fullName: doc.data().fullName,
            contactNumber: doc.data().formatNationalContactNumber,
            username: doc.data().username,
            userAppId: doc.data().userAppId,
            href: doc.data().href,
            avatar: doc.data().avatar,
            createdAt: doc.data().createdAt,
            attendantsSocialChatID: doc.id,
          });
        });
        this.allAttendants = attendants;
      });
    },
    updatePhoneNumber(e) {
      this.socialChatSetting.e164ContactNumber = e.e164
      this.socialChatSetting.formatNationalContactNumber = e.formatNational
    },
    updateSocialChatSettings() {
      if (confirm("Are you sure you want to proceed?")) {
        settingsCollection
          .doc("socialChatSettings")
          .update({
            platforms: this.socialChatSetting.platforms || null,
            chatStyle: this.socialChatSetting.chatStyle || null,
            headerMessagePlatform: this.socialChatSetting.headerMessagePlatform || null,
            iconSrc: this.socialChatSetting.iconSrc || null,
            iconAlt: this.socialChatSetting.iconAlt || null,
            footerDetails: this.socialChatSetting.footerDetails || null,
            companyContact: this.socialChatSetting.companyContact || null,
            e164ContactNumber: this.socialChatSetting.e164ContactNumber,
            formatNationalContactNumber: this.socialChatSetting.formatNationalContactNumber,
            toolPick: this.socialChatSetting.toolPick || null,
            hoursFrom: this.socialChatSetting.hoursFrom || null,
            hoursTo: this.socialChatSetting.hoursTo || null,
            customFooter: this.socialChatSetting.customFooter || null,
          })
          .then(() => {
            this.$notify({
              group: "global",
              type: "success",
              title: "Settings Updated",
            });
          })
          .catch((error) => {
            this.$notify({
              group: "global",
              type: "error",
              title: "Settings Update Error",
              text: error.message,
            });
          });
      }
    },
  },
  computed: {
    sortedAttendants:function() {
      let attendants = this.filteredAttendants
      return attendants.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    filteredAttendants: function () {
      if (this.query !== "") {
        const searchQuery = this.query.toLowerCase();
        return this.allAttendants.filter(
          (item) =>
            (item.socialApp && item.socialApp.toLowerCase().includes(searchQuery)) ||
            (item.titleLabel && item.titleLabel.toLowerCase().includes(searchQuery)) ||
            (item.fullName && item.fullName.toLowerCase().includes(searchQuery)) ||
            (item.contactNumber && item.contactNumber.toLowerCase().includes(searchQuery)) ||
            (item.avatar && item.avatar.toLowerCase().includes(searchQuery))
        );
      }
      return this.allAttendants;
    },
    totalAttendants: function () {
      if (!isNil(this.allAttendants)) {
        return this.allAttendants.length;
      }

      return 0;
    },
  },
  created() {
    this.getAttendants();

    settingsCollection
      .doc("socialChatSettings")
      .get()
      .then((doc) => {
        this.socialChatSetting.platforms = doc.data().platforms;
        this.socialChatSetting.chatStyle = doc.data().chatStyle;
        this.socialChatSetting.headerMessagePlatform = doc.data().headerMessagePlatform;
        this.socialChatSetting.iconSrc = doc.data().iconSrc;
        this.socialChatSetting.iconAlt = doc.data().iconAlt;
        this.socialChatSetting.footerDetails = doc.data().footerDetails;
        this.socialChatSetting.companyContact = doc.data().companyContact;
        this.socialChatSetting.toolPick = doc.data().toolPick;
        this.socialChatSetting.hoursFrom = doc.data().hoursFrom;
        this.socialChatSetting.hoursTo = doc.data().hoursTo;
        this.socialChatSetting.customFooter = doc.data().customFooter;
      });
  },
};
</script>

<style>
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.sorting {
  background: #ffffff33;
}

.heading-text {
  cursor: pointer;
  text-align: center;
  padding: 0.75rem 1.5rem;
}

.heading-text:hover {
  opacity: .7;
  background: #fffff333;
}


.input-tel__input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-color: #6b7280 !important;
}

.input-tel__input:focus {
  border: 2px solid #2563eb !important;
  box-shadow: rgb(37 99 235 / 70%) 0px 0px 0px 0.125rem !important;
}
.input-tel.is-valid .input-tel__label[data-v-e59be3b4] {
  color: #424542 !important;
}
.country-selector__input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-color: #6b7280 !important;
}

.country-selector__input:focus {
  border: 2px solid #2563eb !important;
  outline: 2px solid #2563eb !important;
}

.country-selector__label {
  color: #424542 !important;
}

.phone-container {
  outline: 2px solid transparent;
  outline-offset: 2px;
  width: 100%;
}

.phone-container:focus {
  --tw-border-opacity: 1;
  border-color: rgba(170, 148, 97, var(--tw-border-opacity));
}
</style>
