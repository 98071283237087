<template>
  <!-- Page Content -->
  <div class="my-2">
    <h2 class="my-4 text-4xl font-semibold">Agents</h2>
    <div class="pb-2 flex items-center justify-between text-gray-600">
      <div>
        <span>
          <span class="text-primary"> {{ totalAgents }} </span>
          agents
        </span>
      </div>
<!--      Remove If customer like-->
<!--      <button-->
<!--        class="w-40 flex flex-row items-center justify-center p-2 text-white font-semibold bg-primary rounded cursor-pointer"-->
<!--        type="button"-->
<!--        v-if="hasAccess('agents', 'add')"-->
<!--        @click="gotToAddAgent()"-->
<!--      >-->
<!--        Add agent-->
<!--      </button>-->
    </div>

    <input
      type="search"
      v-model="query"
      placeholder="Search..."
      class="w-full p-2 mb-2"
    />

    <!-- Agent Table Content -->
    <table
      class="min-w-max w-full mt-5 table-auto paginated table-fixed" style="padding: 10px"
      :sticky-header="true"
      :data="filteredAgents"
      :bordered="true"
      :striped="true"
      :narrowed="true"
      :hoverable="true"
      :mobile-cards="true"
      :paginated="true"
      :per-page="10"
    >
      <!-- Agent Table Content Header -->
      <thead>
      <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
        <th
          class="px-2 py-1 text-center"
          v-if="hasAccess('agents', 'edit')"
        >
          Edit
        </th>
        <th
          @click="sort('created')"
          class="heading-text"
          :class="{ sorting: currentSort === 'created' }"
        >
          Created
          <MenuDown
            v-if="isSorted('created', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('created', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('firstName')"
          class="heading-text"
          :class="{ sorting: currentSort === 'firstName' }"
        >
          First Name
          <MenuDown
            v-if="isSorted('firstName', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('firstName', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('lastName')"
          class="heading-text"
          :class="{ sorting: currentSort === 'lastName' }"
        >
          Last Name
          <MenuDown
            v-if="isSorted('lastName', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('lastName', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('email')"
          class="heading-text tableColumnWidth"
          :class="{ sorting: currentSort === 'email' }"
        >
          Email
          <MenuDown
            v-if="isSorted('email', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('email', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('contact')"
          class="heading-text"
          :class="{ sorting: currentSort === 'contact' }"
        >
          Contact Number
          <MenuDown
            v-if="isSorted('contact', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('contact', 'asc')"
            class="inline-block absolute"
          />
        </th>
      </tr>
      </thead>
      <!-- Agent Table Content Body -->
      <tbody
        class="text-gray-600 text-sm font-light"
        v-for="agent in sortedAgents"
        :key="agent.id"
      >
      <tr
        v-if="agent.role === 'agent' || agent.role === 'agent-principal' "
        class="border-b border-gray-200 hover:bg-gray-100"
      >
        <td
          class="py-3 px-3 text-center"
          v-if="hasAccess('agents', 'edit')"
        >
          <div class="flex item-center justify-center">
            <router-link
              :to="{
                  name: 'admin-update-agent',
                  params: { id: agent.profileID },
                }"
              class="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
            >
              <EditIcon />
            </router-link>
          </div>
        </td>
        <td class="py-3 px-3 text-center">
          {{ agent.created.toDate() | moment("calendar") }}
        </td>
        <td class="py-3 px-3 text-center">
          {{ agent.firstName }}
        </td>
        <td class="py-3 px-3 text-center">
          {{ agent.lastName }}
        </td>
        <td class="py-3 px-3 text-center tableColumnWidth">
          {{ agent.email }}
        </td>
        <td class="py-3 px-3 text-center">
          {{ agent.contact }}
        </td>
      </tr>
      </tbody>
    </table>
    <!-- Agent Table Content Pagination Btn -->
    <p class="pagination-container">
      <button
        v-if="currentPage != 1"
        @click="prevPage"
        class="mb-3 px-2 py-1 mr-1 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none bg-primary border border-solid border-primary focus:outline-none"
      >
        &lt; Prev
      </button>

      <button
        v-if="(this.currentPage*this.pageSize) < this.filteredAgents.length"
        @click="nextPage"
        class="mb-3 px-2 py-1 mr-1 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none bg-primary border border-solid border-primary focus:outline-none"
      >
        Next &gt;
      </button>
    </p>
  </div>
</template>

<script>
import { profilesCollection} from "../../firebase.js";
import isNil from "lodash/isNil";
import permissionsMixin from "../../mixins/permissions";

import EditIcon from "vue-material-design-icons/PencilOutline";
import MenuDown from "vue-material-design-icons/MenuDown";
import MenuUp from "vue-material-design-icons/MenuUp";
import {APP_ADMIN_PAGINATION} from "@/Constants";

export default {
  name: "AdminUsersList",
  components: {
    EditIcon,
    MenuDown,
    MenuUp,
  },
  mixins: [permissionsMixin],
  data: () => ({
    currentSort:'created',
    currentSortDir:'desc',
    pageSize: APP_ADMIN_PAGINATION,
    currentPage:1,
    query: "",
    allAgents: [],
    users: [],
    agents: [],
  }),
  methods: {
    isSorted(field, direction) {
      if (this.currentSort === field && this.currentSortDir === direction) {
        return true;
      }
      return false;
    },
    sort:function(s) {
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'desc'?'asc':'desc';
      }
      this.currentSort = s;
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredAgents.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    // ____ Remove If customer like ____
    // gotToAddAgent() {
    //   this.$router.replace({ name: "admin-add-agent" });
    // },

    // getAgents() {
    //   agentsCollection.orderBy("createdAt").onSnapshot((snap) => {
    //     let agents = [];
    //     snap.forEach((doc) => {
    //       agents.push({
    //         firstName: doc.data().firstName,
    //         lastName: doc.data().lastName,
    //         email: doc.data().email,
    //         contact: doc.data().contact,
    //         created: doc.data().createdAt,
    //         order: doc.data().order,
    //         profileID: doc.id,
    //       });
    //     });
    //     this.allAgents = agents;
    //   });
    // },
  },
  computed: {
    sortedAgents:function() {
      let agents = this.filteredAgents
      return agents.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    filteredAgents: function () {
      if (this.query !== "") {
        const searchQuery = this.query.toLowerCase();
        return this.allAgents.filter(
          (item) =>
            (item.firstName &&
              item.firstName.toLowerCase().includes(searchQuery)) ||
            (item.lastName &&
              item.lastName.toLowerCase().includes(searchQuery)) ||
            (item.email && item.email.toLowerCase().includes(searchQuery)) ||
            (item.contactNumber &&
              item.contactNumber.toLowerCase().includes(searchQuery))
        );
      }
      return this.allAgents;
    },
    totalAgents: function () {
      if (!isNil(this.allAgents)) {
        return this.allAgents.length;
      }

      return 0;
    },
  },
  created() {
    // this.getAgents();
    profilesCollection
      .where("role", "==", "agent")
      // .where("role", "==", "agent-principal")
      .get()
      .then((snap) => {
        let allAgents = [];
        snap.forEach((doc) => {
          allAgents.push({
            firstName: doc.data().firstName,
            lastName: doc.data().lastName,
            email: doc.data().email,
            contact: doc.data().formatNationalContactNumber,
            created: doc.data().created,
            role: doc.data().role,
            order: doc.data().order,
            profileID: doc.id,
          });
        });
        this.allAgents = allAgents;
      });
  },
};
</script>

<style>
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.sorting {
  background: #ffffff33;
}

.heading-text {
  cursor: pointer;
  text-align: center;
  padding: 0.75rem 1.5rem;
}

.heading-text:hover {
  opacity: .7;
  background: #fffff333;
}

.tableColumnWidth {
  width: 20%;
  word-wrap: break-word
}
</style>
